<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3 d-none d-md-block">
                        <div class="getStart bg-white boxed box-shadow guide_lines" id="getstarted">
                            <div class="repeatList">
                                <a class="medium d-flex main-link active" data-bs-toggle="collapse" href="#collapseExample"
                                    role="button" aria-expanded="true" aria-controls="collapseExample"> {{$t('affiliate_guide.affiliateTab1')}}
                                </a>
                                <div class="collapse selected show" id="collapseExample" data-bs-parent="#getstarted">
                                    <a class="js-anchor-link d-flex" href="#affiliates">{{$t('affiliate_guide.affiliateTab2')}}</a>
                                    <a class="js-anchor-link d-flex active" href="#affiliates">{{$t('affiliate_guide.affiliateTab3')}}</a>
                                    <a class="js-anchor-link d-flex" href="#affiliates">{{$t('affiliate_guide.affiliateTab4')}}</a>
                                    <a class="js-anchor-link d-flex" href="#affiliates">{{$t('affiliate_guide.affiliateTab5')}}</a>
                                </div>
                            </div>
                            <div class="repeatList">
                                <a class="medium d-flex main-link" data-bs-toggle="collapse" href="#collapseExample1"
                                    role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('affiliate_guide.affiliateTab6')}}</a>
                                <div class="collapse selected" id="collapseExample1" data-bs-parent="#getstarted">
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab7')}} </a>
                                    <!-- <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab8')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab9')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab10')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab11')}}</a> -->
                                </div>
                            </div>
                            <!-- Demo -->
                            <div class="repeatList">
                                <a class="medium d-flex main-link" data-bs-toggle="collapse" href="#collapseExample1"
                                    role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('affiliate_guide.affiliateTab8')}}</a>
                                <div class="collapse selected" id="collapseExample1" data-bs-parent="#getstarted">
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab25')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab26')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab9')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab10')}} </a>
                                    <a class="js-anchor-link d-flex" href="#affiliateAccount">{{$t('affiliate_guide.affiliateTab11')}}</a>
                                </div>
                            </div>
                            <!-- End -->
                            <div class="repeatList">
                                <a class="js-anchor-link medium d-flex main-link" href="#accountPolicy">{{$t('affiliate_guide.affiliateTab12')}}</a>
                            </div>
                            <div class="repeatList">
                                <a class="medium d-flex main-link" data-bs-toggle="collapse" href="#collapseExample2"
                                    role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('affiliate_guide.affiliateTab13')}}</a>
                                <div class="collapse selected" id="collapseExample2" data-bs-parent="#getstarted">
                                    <a class="js-anchor-link d-flex" href="#promotionalGuidelines">{{$t('affiliate_guide.affiliateTab14')}} </a>
                                    <a class="js-anchor-link d-flex" href="#promotionalGuidelines">{{$t('affiliate_guide.affiliateTab15')}} </a>
                                    <a class="js-anchor-link d-flex" href="#promotionalGuidelines">{{$t('affiliate_guide.affiliateTab16')}} </a>
                                </div>
                            </div>
                            <div class="repeatList">
                                <a class="medium d-flex main-link" data-bs-toggle="collapse" href="#collapseExample3"
                                    role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('affiliate_guide.affiliateTab17')}} </a>
                                <div class="collapse selected" id="collapseExample3" data-bs-parent="#getstarted">
                                    <a class="js-anchor-link d-flex" href="#payments">{{$t('affiliate_guide.affiliateTab18')}}</a>
                                    <a class="js-anchor-link d-flex" href="#payments">{{$t('affiliate_guide.affiliateTab19')}}</a>
                                </div>
                            </div>
                            <div class="repeatList">
                                <a class="medium d-flex main-link js-anchor-link" href="#supportAssistance">{{$t('affiliate_guide.affiliateTab20')}}</a>
                            </div>
                            <div class="repeatList">
                                <a class="medium d-flex main-link js-anchor-link" href="#supportAssistance">{{$t('affiliate_guide.affiliateTab27')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="bg-white boxed box-shadow p-md-4">
                            <!-- Main item -->
                            <div class="inner-box" id="affiliates">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('zuluTrade_affiliates.affiliateTitle')}} </h3>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A1"
                                        role="button" aria-expanded="true" aria-controls="collapseExample">{{$t('zuluTrade_affiliates.affiliateQuestion1')}} </a>
                                    <div class="collapse selected show" id="A1"   data-bs-parent="#affiliates">                                       
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer1')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer2')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer3')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer4')}}</p>
                                        <p class="text-uppercase" v-html="$t('zuluTrade_affiliates.affiliateAnswer5')"></p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer6')}} </p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A2"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('zuluTrade_affiliates.affiliateQuestion3')}}</a>
                                    <div class="collapse selected" id="A2" data-bs-parent="#affiliates">
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer7')}}</p>
                                        <p class="fw-bold mb-2">{{$t('zuluTrade_affiliates.affiliateAnswer8')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer9')}}</p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A3"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('zuluTrade_affiliates.affiliateQuestion2')}}</a>
                                    <div class="collapse selected" id="A3"  data-bs-parent="#affiliates">
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer10')}} </p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer11')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer12')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer13')}}</p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#A4"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('zuluTrade_affiliates.affiliateQuestion4')}}</a>
                                    <div class="collapse selected" id="A4" data-bs-parent="#affiliates">
                                        <p class="fw-bold mb-2">{{$t('zuluTrade_affiliates.affiliateAnswer14')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer15')}}</p>
                                        <p class="fw-bold mb-2">{{$t('zuluTrade_affiliates.affiliateAnswer16')}}</p>
                                        <p>{{$t('zuluTrade_affiliates.affiliateAnswer17')}}</p>
                                    </div>
                                </div>
                            </div>

                            <!-- Main item -->
                            <div class="inner-box" id="affiliateAccount">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('Creating_affiliate_account.contentTitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#B1"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('Creating_affiliate_account.contentTab1')}}</a>
                                    <div class="collapse selected" id="B1" data-bs-parent="#affiliateAccount">
                                        <p> <span v-html="$t('Creating_affiliate_account.contentanswer1')"></span><br>
                                            <span v-html="$t('Creating_affiliate_account.contentanswer2')"></span><br>
                                            <span v-html="$t('Creating_affiliate_account.contentanswer3')"></span><br>
                                            <span v-html="$t('Creating_affiliate_account.contentanswer4')"></span><br>
                                            <span v-html="$t('Creating_affiliate_account.contentanswer5')"></span></p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#B2"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('Creating_affiliate_account.contentTab2')}}</a>
                                    <div class="collapse selected" id="B2" data-bs-parent="#affiliateAccount">
                                        <p class="fw-bold mb-2">{{$t('Creating_affiliate_account.contentanswer6')}}</p>
                                        <p>{{$t('Creating_affiliate_account.contentanswer7')}}</p>
                                        <div class="ps-4">
                                            <p v-html="$t('Creating_affiliate_account.contentanswer8')"></p>
                                            <p v-html="$t('Creating_affiliate_account.contentanswer9')"></p>
                                        </div>
                                        <p class="fw-bold mb-2">{{$t('Creating_affiliate_account.contentanswer10')}}</p>
                                        <p v-html="$t('Creating_affiliate_account.contentanswer11')"> </p>
                                        <p class="fw-bold mb-2"> {{$t('Creating_affiliate_account.contentanswer12')}}</p>
                                        <p v-html="$t('Creating_affiliate_account.contentanswer13')"></p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#B3"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('Creating_affiliate_account.contentTab3')}}</a>
                                    <div class="collapse selected" id="B3" data-bs-parent="#affiliateAccount">
                                        <p><span v-html="$t('Creating_affiliate_account.contentanswer14')"></span><br>
                                        <span v-html="$t('Creating_affiliate_account.contentanswer15')"></span><br>
                                    <span v-html="$t('Creating_affiliate_account.contentanswer21')"></span></p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#B4"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('Creating_affiliate_account.contentTab4')}}</a>
                                    <div class="collapse selected" id="B4" data-bs-parent="#affiliateAccount">
                                        <p>{{$t('Creating_affiliate_account.contentanswer16')}}</p>
                                    </div>
                                </div>

                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#B5"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('Creating_affiliate_account.contentTab6')}}</a>
                                    <div class="collapse selected" id="B5" data-bs-parent="#affiliateAccount">
                                        <p>{{$t('Creating_affiliate_account.contentanswer17')}}</p>
                                        <ol class="ps-5 mb-4" type="1">
                                            <li class="ps-1">{{$t('Creating_affiliate_account.contentanswer18')}}</li>
                                            <li class="ps-1" v-html="$t('Creating_affiliate_account.contentanswer19')"></li>
                                        </ol>
                                        <p v-html="$t('Creating_affiliate_account.contentanswer20')"></p>
                                    </div>
                                </div>
                            </div>

                            <!-- Main item -->
                            <div class="inner-box" id="accountPolicy">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('account_linkage_policy.contentTitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="linkage-policy">
                                    <ul class="mb-4">
                                        <li>{{$t('account_linkage_policy.contentanswer1')}}</li>
                                        <li>{{$t('account_linkage_policy.contentanswer2')}}</li>
                                        <li>{{$t('account_linkage_policy.contentanswer3')}}</li>
                                        <li v-html="$t('account_linkage_policy.contentanswer4')"></li>
                                        <li>{{$t('account_linkage_policy.contentanswer5')}}</li>
                                        <li>{{$t('account_linkage_policy.contentanswer6')}}</li>
                                    </ul>
                                    <div class="examle-sec">
                                        <p class="mb-3 fw-bold">{{$t('account_linkage_policy.contentanswer7')}}</p>
                                        <p v-html="$t('account_linkage_policy.contentanswer8')"></p>
                                        <div class="example-item mt-5">
                                            <div class="sr-no">{{$t('account_linkage_policy.contentanswer9')}}</div>
                                            <p v-html="$t('account_linkage_policy.contentanswer10')"></p>
                                            <svg class="arrow-icn mx-auto d-block mb-3" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 21a1 1 0 0 1-.71-.29l-8-8a1 1 0 1 1 1.42-1.42l7.29 7.3 7.29-7.3a1 1 0 0 1 1.42 1.42l-8 8A1 1 0 0 1 16 21Z" data-name="Layer 2" fill="#ff7200" class="fill-000000"></path></svg>
                                            <p class="mb-1" v-html="$t('account_linkage_policy.contentanswer11')"></p>
                                        </div>

                                        <div class="example-item mt-5">
                                            <div class="sr-no">{{$t('account_linkage_policy.contentanswer12')}}</div>
                                            <p>{{$t('account_linkage_policy.contentanswer13')}}</p>
                                            <svg class="arrow-icn mx-auto d-block mb-3" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 21a1 1 0 0 1-.71-.29l-8-8a1 1 0 1 1 1.42-1.42l7.29 7.3 7.29-7.3a1 1 0 0 1 1.42 1.42l-8 8A1 1 0 0 1 16 21Z" data-name="Layer 2" fill="#ff7200" class="fill-000000"></path></svg>
                                            <p class="mb-1" v-html="$t('account_linkage_policy.contentanswer14')"></p>
                                        </div>
                                        <div class="example-item mt-5 mb-4">
                                            <div class="sr-no">{{$t('account_linkage_policy.contentanswer19')}}</div>
                                            <p>{{$t('account_linkage_policy.contentanswer15')}}</p>
                                            <svg class="arrow-icn mx-auto d-block mb-3" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 21a1 1 0 0 1-.71-.29l-8-8a1 1 0 1 1 1.42-1.42l7.29 7.3 7.29-7.3a1 1 0 0 1 1.42 1.42l-8 8A1 1 0 0 1 16 21Z" data-name="Layer 2" fill="#ff7200" class="fill-000000"></path></svg>
                                            <p>{{$t('account_linkage_policy.contentanswer16')}}</p>
                                            <svg class="arrow-icn mx-auto d-block mb-3" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 21a1 1 0 0 1-.71-.29l-8-8a1 1 0 1 1 1.42-1.42l7.29 7.3 7.29-7.3a1 1 0 0 1 1.42 1.42l-8 8A1 1 0 0 1 16 21Z" data-name="Layer 2" fill="#ff7200" class="fill-000000"></path></svg>
                                            <p v-html="$t('account_linkage_policy.contentanswer17')"></p>
                                            <svg class="arrow-icn mx-auto d-block mb-3" viewBox="0 0 32 32" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 21a1 1 0 0 1-.71-.29l-8-8a1 1 0 1 1 1.42-1.42l7.29 7.3 7.29-7.3a1 1 0 0 1 1.42 1.42l-8 8A1 1 0 0 1 16 21Z" data-name="Layer 2" fill="#ff7200" class="fill-000000"></path></svg>
                                            <p class="mb-1" v-html="$t('account_linkage_policy.contentanswer18')"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Main item -->
                            <div class="inner-box" id="promotionalGuidelines">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('advertising_promotional_guidelines.contentTitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#C1"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('advertising_promotional_guidelines.contentTab1')}} </a>
                                    <div class="collapse selected" id="C1" data-bs-parent="#promotionalGuidelines">
                                        <ul class="mb-4 list-style-circle">
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer1')}}</li>
                                            <li v-html="$t('advertising_promotional_guidelines.contentanswer2')"></li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer3')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer4')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer5')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer6')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer7')}}</li>
                                        </ul>
                                        <div class="card-with-border ppc-card mb-4">
                                            <div class="guiderline-table-outer-header">
                                              <h6>{{$t('advertising_promotional_guidelines.contentTab2')}}</h6>
                                            </div>
                                            <div class="guiderline-table-container">
                                              <table class="table">
                                                <tbody>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer8')}}</td>
                                                    <td class="left text-center">
                                                        <v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer9')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer10')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer11')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer12')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer13')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer14')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="cross-img" width="16" height="16" src="/assets/images/red-cross.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer15')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer16')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer17')}}</td>
                                                    <td class="left"></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer18')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer19')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer20')}}</td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer21')}} </td>
                                                    <td class="left text-center"><v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" /></td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer22')}}</td>
                                                    <td class="left text-center">
                                                      <v-lazy-image class="check-img" width="18" height="18" src="/assets/images/check_green.svg" />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="left">{{$t('advertising_promotional_guidelines.contentanswer23')}}</td>
                                                    <td class="left text-center">{{$t('advertising_promotional_guidelines.contentanswer24')}} </td>
                                                  </tr>           
                                                </tbody>
                                              </table>
                                            </div>
                                        </div>

                                        <p v-html="$t('advertising_promotional_guidelines.contentanswer25')"></p>
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer26')}}</p>

                                        <ul class="ps-5 mb-4 list-style-circle">
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer27')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer28')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer29')}}</li>
                                            <li class="">{{$t('advertising_promotional_guidelines.contentanswer30')}}</li>
                                        </ul>
                                        
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#C2"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('advertising_promotional_guidelines.contentTab3')}} </a>
                                    <div class="collapse selected" id="C2" data-bs-parent="#promotionalGuidelines">
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer32')}}</p>
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer33')}}</p>
                                        <p class="fw-bold mb-2">{{$t('advertising_promotional_guidelines.contentanswer34')}}</p>
                                        <p class="fw-bold mb-2">{{$t('advertising_promotional_guidelines.contentanswer47')}}</p>
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer45')}}</p>
                                        <p class="fw-bold mb-2">{{$t('advertising_promotional_guidelines.contentanswer44')}}</p>
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer46')}}</p>
                                        <p>{{$t('advertising_promotional_guidelines.contentanswer48')}}</p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#C3"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('advertising_promotional_guidelines.contentTab4')}}</a>
                                    <div class="collapse selected" id="C3" data-bs-parent="#promotionalGuidelines">
                                        <ul class="mb-4 list-style-circle">
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer35')}}</li>
                                            <li v-html="$t('advertising_promotional_guidelines.contentanswer36')"></li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer37')}}</li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer38')}}</li>
                                            <li v-html="$t('advertising_promotional_guidelines.contentanswer39')"></li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer40')}}</li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer41')}}</li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer42')}}</li>
                                            <li>{{$t('advertising_promotional_guidelines.contentanswer43')}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- Main item -->
                            <div class="inner-box" id="payments">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('payments.contentTitle')}}</h3>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#D1"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('payments.contentTab1')}}</a>
                                    <div class="collapse selected" id="D1" data-bs-parent="#payments">
                                        <p>{{$t('payments.contentanswer1')}} </p>
                                        <p v-html="$t('payments.contentanswer2')"></p>
                                        <p v-html="$t('payments.contentanswer3')"></p>
                                        <p>{{$t('payments.contentanswer4')}}</p>
                                    </div>
                                </div>
                                <!-- Accordion item -->
                                <div class="fullList">
                                    <a class="d-flex f-18 arrow" data-bs-toggle="collapse" href="#D2"
                                        role="button" aria-expanded="false" aria-controls="collapseExample">{{$t('payments.contentTab2')}}</a>
                                    <div class="collapse selected" id="D2" data-bs-parent="#payments">
                                        
                                        <p>{{$t('payments.contentanswer5')}}</p>
                                        <p>{{$t('payments.contentanswer6')}}</p>
                                        <p>{{$t('payments.contentanswer7')}}</p>
                                        <ul class="ps-5 mb-4 list-style-circle">
                                            <li>{{$t('payments.contentanswer8')}}</li>
                                            <li>{{$t('payments.contentanswer9')}}</li>
                                            <li>{{$t('payments.contentanswer10')}}</li>
                                            <li>{{$t('payments.contentanswer11')}}</li>
                                            <li>{{$t('payments.contentanswer12')}}</li>
                                            <li>{{$t('payments.contentanswer13')}}</li>
                                            <li>{{$t('payments.contentanswer14')}}</li>
                                            <li>{{$t('payments.contentanswer15')}}</li>
                                            <li v-html="$t('payments.contentanswer16')"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- Main item -->
                            <div class="inner-box" id="supportAssistance">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('support_assistance.contentTitle')}}</h3>
                                    <div class="selected support_sec">
                                        <p v-html="$t('support_assistance.contentanswer1')"></p>
                                        <p>{{$t('support_assistance.contentanswer2')}}</p>

                                        <ul class="mb-4 ps-4">
                                            <li>{{$t('support_assistance.contentanswer3')}}</li>
                                            <li>{{$t('support_assistance.contentanswer4')}}</li>
                                            <li>{{$t('support_assistance.contentanswer5')}}</li>
                                            <li>{{$t('support_assistance.contentanswer6')}}</li>
                                            <li>{{$t('support_assistance.contentanswer7')}}</li>
                                            <li>{{$t('support_assistance.contentanswer8')}}</li>
                                            <li>{{$t('support_assistance.contentanswer9')}}</li>
                                            <li>{{$t('support_assistance.contentanswer10')}}</li>
                                        </ul>

                                        <p v-html="$t('support_assistance.contentanswer12')"></p>

                                        <ul class="mb-4 ps-4">
                                            <li>{{$t('support_assistance.contentanswer13')}}</li>
                                            <li>{{$t('support_assistance.contentanswer14')}}</li>
                                            <li>{{$t('support_assistance.contentanswer15')}}</li>
                                            <li>{{$t('support_assistance.contentanswer16')}}</li>
                                            <li>{{$t('support_assistance.contentanswer17')}}</li>
                                            <li>{{$t('support_assistance.contentanswer18')}}</li>
                                            <li>{{$t('support_assistance.contentanswer19')}}</li>
                                            <li>{{$t('support_assistance.contentanswer20')}}</li>
                                            <li>{{$t('support_assistance.contentanswer11')}}</li>
                                        </ul>

                                        <p v-html="$t('support_assistance.contentanswer21')"></p>

                                        <p class="fw-bold mb-2" v-html="$t('support_assistance.contentanswer22')"></p>
                                        <p>{{$t('support_assistance.contentanswer23')}}</p>
                                        <ul class="mb-4 ps-4">
                                            <li>{{$t('support_assistance.contentanswer24')}}</li>
                                            <li>{{$t('support_assistance.contentanswer25')}}</li>
                                            <li>{{$t('support_assistance.contentanswer26')}}</li>
                                            <li v-html="$t('support_assistance.contentanswer27')"></li>
                                        </ul>

                                        <p>{{$t('support_assistance.contentanswer28')}}</p>

                                        <p>{{$t('support_assistance.contentanswer29')}}</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- MODAL START CHECK ALL OUR PHONE NUMBERS  -->
    <div class="modal fade modal-680" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <p class="mb-0 f-20 fw-300">{{$t('number_list.title')}}</p>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <div class="col-12 col-md-4">
                        <div class="card-body text-center">
                            <span><v-lazy-image class="d-block mx-auto" src="/assets/images/europeIcon.png" :alt="$t('affiliate_guide.affiliateTab21')" /></span>
                            <p class="medium">{{$t('number_list.name1')}}</p>
                            <ul class="listhelp">
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name2')}}</span>
                                    <a class="bold" href="tel:+44 20 3355 8805">{{$t('number_list.number1')}}</a>
                                </li>
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name3')}}</span>
                                    <a class="bold" href="tel:+7 499 918 7378">{{$t('number_list.number2')}}</a>
                                </li>
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name4')}}</span>
                                    <a class="bold" href="tel:+34 911 87 7655">{{$t('number_list.number3')}}</a>
                                </li>
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name5')}}</span>
                                    <a class="bold" href="tel:+30 213 0117 499">{{$t('number_list.number4')}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card-body text-center">
                            <span><v-lazy-image class="d-block mx-auto" src="/assets/images/americaIcon.png" :alt="$t('affiliate_guide.affiliateTab22')" /></span>
                            <!-- <p class="medium">{{$t('number_list.title6')}}</p> -->
                            <ul class="listhelp">
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name7')}}</span>
                                    <a class="bold" href="tel:+44 20 3355 8805">{{$t('number_list.number5')}}</a>
                                </li>
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name8')}}</span>
                                    <a class="bold" href="tel:+57 2 891 2645">{{$t('number_list.number6')}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card-body text-center">
                            <span><v-lazy-image class="d-block mx-auto" src="/assets/images/australiaIcon.png"
                                    :alt="$t('affiliate_guide.affiliateTab23')" /></span>
                            <p class="medium">{{$t('number_list.name9')}}</p>
                            <ul class="listhelp">
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name10')}}</span>
                                    <a class="bold" href="tel:+81 3 4588 8774">{{$t('number_list.number7')}}</a>
                                </li>
                                <li>
                                    <span class="gray f-14 d-block">{{$t('number_list.name11')}}</span>
                                    <a class="bold" href="tel:+61 3 9008 4202">{{$t('number_list.number8')}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    setup() {

    },
    data() {
        return {
            
        };
    },
    mounted() {
        $('.js-anchor-link').click(function(e){
            e.preventDefault();
            var target = $($(this).attr('href'));
            if(target.length){
                var scrollTo = target.offset().top;
                $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
            }
        });
    },
}
</script>
